/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import './App.css';

import './assets/styles/styles.scss';
import 'react-datetime/css/react-datetime.css';
import React, { lazy, Suspense, useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Navigate } from 'react-router-dom';
import Context from './components/context/context';
import ScrollToTop from './components/misc/ScrollToTop';
import CustomSwitch from './components/misc/CustomSwitch';
import useGlobalState from './components/store/useGlobalState';
import translationState from './components/store/translationState';
import { ThemeProvider } from './components/context/ThemeContext';
import { ContextProvider } from './components/misc/context';
import useToken from './assets/scripts/useToken';
import PopupPlayer from './components/misc/popupPlayer';

import Navigation from './components/global/navigation';
import NavigationVideo from './components/global/navigation-video';
import FAQs from './components/misc/FAQs';
import { Provider, ErrorBoundary } from '@rollbar/react'; // Provider imports 'rollbar'

const Home = lazy(() => import('./views/pages/home'));
const Login = lazy(() => import('./views/pages/login'));
const Register = lazy(() => import('./views/pages/register'));
const Channel = lazy(() => import('./views/pages/channel/channel'));
const Videos = lazy(() => import('./views/pages/videos/videos'));
const Stream = lazy(() => import('./views/pages/stream/stream'));
const Trending = lazy(() => import('./views/pages/trending'));
const Subscriptions = lazy(() => import('./views/pages/subscriptions'));
const History = lazy(() => import('./views/pages/history'));
const Playlist = lazy(() => import('./views/pages/playlist'));
const Channels = lazy(() => import('./views/pages/channels'));
const CreateChannelPlaylist = lazy(() => import('./views/pages/createChannelPlaylist'));

const WatchPlaylist = lazy(() => import('./views/pages/playlist/playlist'));

const Playlists = lazy(() => import('./views/pages/playlists'));
const LikedVideos = lazy(() => import('./views/pages/likedvideos'));
const Results = lazy(() => import('./views/pages/results'));
const Library = lazy(() => import('./views/pages/library'));
const Studio = lazy(() => import('./views/pages/studio/studio'));
const Ceclips = lazy(() => import('./views/pages/ceclips'));
const PasswordReset = lazy(() => import('./views/pages/passwordReset'));
const Privacy = lazy(() => import('./views/pages/privacy'));

const Join = lazy(() => import('./views/pages/angles/join'));

function App() {
  const token = useToken();

  const store = useGlobalState();
  const translation = translationState();
  const [popup, setPopUp] = useState(false);
  const [player, setPlayer] = useState([]);
  const [languages, setLanguages] = useState([]);

  let user = '';
  try {
    user = JSON.parse(localStorage.getItem('ceflix.user') || '');
  } catch {
    user = '';
  }

  async function countView(id) {
    const req = await fetch(`${process.env.REACT_APP_API_URL}countvideoview`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Application-Key': `${process.env.REACT_APP_API}`,
        'X-TOKEN': `${token.token}`
      },
      body: JSON.stringify({
        email: user.email,
        video: id,
        language: null
      })
    });
    const res = await req.json();
  }

  async function countViewGuest(id) {
    const req = await fetch(`${process.env.REACT_APP_API_URL}video/offline-view-count`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Application-Key': `${process.env.REACT_APP_API}`,
        'X-TOKEN': `${token.token}`
      },
      body: JSON.stringify({
        video: id,
        device: navigator.userAgent
      })
    });
    const res = await req.json();
  }

  async function getLan(id, data) {
    const req = await fetch(`${process.env.REACT_APP_API_URL}video/languages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Application-Key': `${process.env.REACT_APP_API}`
      },
      body: JSON.stringify({ video: id })
    });

    const res = await req.json();

    if (res.status) {
      setPlayer({
        url: data.url,
        videoId: data.videoID,
        title: data.videoTitle,
        languages: res.data
      });

      if (window.location.pathname === '/') {
        if (user !== '') {
          countView(data.videoID);
        } else {
          countViewGuest(data.videoID);
        }
      }

      setPopUp(true);
    }
  }

  async function getPlayer() {
    const req = await fetch(`${process.env.REACT_APP_API_URL}home/player`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Application-Key': `${process.env.REACT_APP_API}`
      }
    });

    const res = await req.json();

    if (res.status) {
      getLan(res.videoID, res);
    }
  }

  useEffect(() => {
    getPlayer();
  }, []);

  const rollbarConfig = {
    accessToken: 'caf743d64aca4e418448e8eb82b14a29',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: 'production',
    server: {
      root: 'http://ceflix.org/',
      branch: 'main'
    },
    code_version: '0.0.1',
    person: {
      id: user.id,
      email: user.email,
      username: user.username
    }
  };

  function TestError() {
    const a = null;
    return a.hello();
  }

  return (
    <div className="App min-h-screen relative">
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          {window.location.pathname === '/' && popup && (
            <PopupPlayer data={player} func={setPopUp} />
          )}
          <Context.Provider value={useMemo(() => ({ store, translation }))}>
            <ThemeProvider>
              <ContextProvider>
                <Router>
                  <ScrollToTop />
                  <Suspense fallback={<span className="sr-only">Loading...</span>}>
                    <CustomSwitch>
                      <Route
                        path="/"
                        element={
                          <Navigation>
                            <Home />
                          </Navigation>
                        }
                      />
                      <Route
                        path="/channel/playlist/create/:id"
                        element={
                          <Navigation>
                            <CreateChannelPlaylist />
                          </Navigation>
                        }
                      />
                      <Route
                        path="/passwordreset"
                        element={!token ? <Navigate to="/" replace /> : <PasswordReset />}
                      />
                      <Route
                        path="/login"
                        element={
                          !token ? (
                            <Navigate to="/" replace />
                          ) : (
                            <Navigation>
                              <Login />
                            </Navigation>
                          )
                        }
                      />
                      <Route
                        path="/register"
                        element={
                          <Navigation>
                            <Register />
                          </Navigation>
                        }
                      />
                      <Route
                        path="/faqs"
                        element={
                          <Navigation>
                            <FAQs />
                          </Navigation>
                        }
                      />
                      <Route
                        path="/privacy"
                        element={
                          <Navigation>
                            <Privacy />
                          </Navigation>
                        }
                      />

                      <Route
                        path="/ceclips"
                        element={
                          <Navigation>
                            <Ceclips />
                          </Navigation>
                        }
                      />
                      <Route
                        path="/channels"
                        element={
                          token.token === '' ? (
                            <Login />
                          ) : (
                            <Navigation>
                              <Channels />
                            </Navigation>
                          )
                        }
                      />
                      <Route
                        path="/channel/:id"
                        element={
                          <Navigation>
                            <Channel />
                          </Navigation>
                        }
                      />
                      <Route
                        path="/videos/watch/:video_id/*"
                        element={
                          <NavigationVideo>
                            <Videos />
                          </NavigationVideo>
                        }
                      />
                      <Route
                        path="/live/:stream_id/*"
                        element={
                          token.token === '' ? (
                            <Login />
                          ) : (
                            <Navigation>
                              <Stream />
                            </Navigation>
                          )
                        }
                      />
                      <Route
                        path="/angles/join/:id"
                        element={
                          <NavigationVideo>
                            <Join />
                          </NavigationVideo>
                        }
                      />
                      <Route
                        path="/playlist/play/:playlist_id/:video_id"
                        element={
                          <NavigationVideo>
                            <WatchPlaylist />
                          </NavigationVideo>
                        }
                      />
                      <Route
                        path="/trending"
                        element={
                          <Navigation>
                            <Trending />
                          </Navigation>
                        }
                      />
                      <Route
                        path="/subscriptions"
                        element={
                          token.token === '' ? (
                            <Login />
                          ) : (
                            <Navigation>
                              <Subscriptions />
                            </Navigation>
                          )
                        }
                      />

                      <Route
                        path="/history"
                        element={
                          token.token === '' ? (
                            <Login />
                          ) : (
                            <Navigation>
                              <History />
                            </Navigation>
                          )
                        }
                      />
                      <Route
                        path="/playlist/:id"
                        element={
                          token.token === '' ? (
                            <Login />
                          ) : (
                            <Navigation>
                              <Playlist />
                            </Navigation>
                          )
                        }
                      />
                      <Route
                        path="/playlists"
                        element={
                          token.token === '' ? (
                            <Login />
                          ) : (
                            <Navigation>
                              <Playlists />
                            </Navigation>
                          )
                        }
                      />
                      <Route
                        path="/likedvideos"
                        element={
                          token.token === '' ? (
                            <Login />
                          ) : (
                            <Navigation>
                              <LikedVideos />
                            </Navigation>
                          )
                        }
                      />
                      <Route
                        path="/results"
                        element={
                          <Navigation>
                            <Results />
                          </Navigation>
                        }
                      />
                      <Route
                        path="/library"
                        element={
                          token.token === '' ? (
                            <Login />
                          ) : (
                            <Navigation>
                              <Library />
                            </Navigation>
                          )
                        }
                      />
                      <Route
                        path="/studio/*"
                        element={token.token === '' ? <Login /> : <Studio />}
                      />
                    </CustomSwitch>
                  </Suspense>
                </Router>
              </ContextProvider>
            </ThemeProvider>
          </Context.Provider>
        </ErrorBoundary>
      </Provider>
    </div>
  );
}

export default App;
